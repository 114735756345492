import { styled, TextField } from "@mui/material";

const CTextField = styled(TextField)(({ theme }) => ({
  "background": theme.palette.common.white,
  "borderRadius": "4px",
  "& .MuiFormLabel-root": { fontSize: "14px" },
  "& .MuiFormControl-root": { height: "40px" },
  "& .MuiInputBase-input": {
    fontSize: "14px",
    minWidth: "55px !important",
    padding: "0 4px 1px 2px !important",
  },
}));

export default CTextField;
