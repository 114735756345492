import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { REDUCER, ACTION } from "@common/constants/redux";

const initialState = {
  customer: null,
  filter: null,
};

const persistConfig = {
  key: REDUCER.CUSTOMER,
  storage: storage,
};

const customerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ACTION.SET_CUSTOMER:
    return {
      ...state,
      customer: payload,
    };
  case ACTION.SET_CUSTOMER_FILTER:
    return {
      ...state,
      filter: payload,
    };

  default:
    return state;
  }
};

export default persistReducer(persistConfig, customerReducer);
