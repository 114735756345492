import React, { useState, useMemo, Suspense, useEffect } from "react";

import { BrowserRouter, Switch } from "react-router-dom";

import { Box, ThemeProvider } from "@mui/material";

import { LoginPage } from "@auth-module/pages";

import { CHeader } from "@common/components/layout/Main";
import { CLoading } from "@common/components/controls";
import { CNotFound, CScrollToTop } from "@common/components/others";
import { PrivateRoute, PublicRoute } from "@common/components/routes";

import { UserContext } from "@common/context/UserContext";

import theme from "@theme";
import { getMessaging, onMessage } from "firebase/messaging";
import {
  app,
  checkPermissionNotification,
  requestPermission,
} from "../firebase/firebase";
import { useSelector } from "react-redux";
import { routes, routesReservation } from "@routes";

import { isLogin } from "@utils/func";
import { getReservation } from "@apis/booking-room.api";

const Routes = () => {
  const _user = useSelector((state) => state.auth.user);

  const [user, setUser] = useState(null);

  const [noti, setNoti] = useState(false);

  const [bookingData, setBookingData] = useState(null);

  const [loading, setLoading] = useState(false);

  const value = useMemo(
    () => ({
      user,
      setUser,
      noti,
      setNoti,
      loading,
      setLoading,
    }),
    // eslint-disable-next-line comma-dangle
    [user, setUser, noti, setNoti]
  );

  const messaging = getMessaging();

  let intervalId = null;

  useEffect(() => {
    onMessage(messaging, (payload) => {
      let isOriginalTitle = true;

      console.log("useEffect");

      if (payload) {
        setNoti(true);

        document.title = payload.notification.title;

        console.log("noti", payload);

        clearInterval(intervalId);

        intervalId = setInterval(() => {
          if (isOriginalTitle) {
            document.title = "CRM CMS";
          } else {
            document.title = payload?.notification?.title || "CRM CMS";
          }

          isOriginalTitle = !isOriginalTitle;
        }, 1000);
      }
    });

    return () => {
      clearInterval(intervalId);
    };
  }, [app]);

  const handleClickAnywhere = () => {
    // clearInterval(intervalId);
    // document.title = "CRM CMS";

    setLoading(true);
    setNoti(true);
  };

  useEffect(() => {
    if (isLogin()) {
      const fetchData = async () => {
        const data = await getReservation({
          pages: 0,
          page: 1,
          status: 0,
        });

        if (data) {
          setBookingData(data?.data);
        }
      };

      fetchData();
    }
  }, [loading]);

  useEffect(() => {
    requestPermission();
    // if(requestPermission)
    // checkPermissionNotification();
  }, []);

  useEffect(() => {
    console.log("useEffect2");
    if (isLogin() && bookingData?.amount > 0) {
      console.log("booking data");
      let isOriginalTitle = true;
      document.title = `CRM CMS`;
      clearInterval(intervalId);
      intervalId = setInterval(() => {
        if (isOriginalTitle) {
          document.title = "CRM CMS";
        } else {
          document.title = `Có ${bookingData?.amount} đơn đặt phòng mới!`;
        }
        isOriginalTitle = !isOriginalTitle;
      }, 1000);
      // setIntervalId(newIntervalId);
    } else {
      clearInterval(intervalId);
      document.title = "CRM CMS";
    }

    setLoading(false);
    return () => {
      clearInterval(intervalId);
    };
  }, [isLogin(), bookingData?.amount, intervalId, loading, app]);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <UserContext.Provider value={value}>
          <Suspense fallback={<CLoading />}>
            <CScrollToTop />
            <Box
              component="section"
              className="c-layout"
              onClick={handleClickAnywhere}
            >
              <PrivateRoute component={CHeader} />
              <div className="c-body-content">
                <Switch>
                  <PublicRoute
                    restricted={true}
                    component={LoginPage}
                    path="/"
                    exact
                  />

                  {_user?.reservation
                    ? routesReservation.map((item, index) => (
                        <PrivateRoute
                          component={item.component}
                          path={item.path}
                          exact
                          key={index}
                        />
                      ))
                    : routes.map((item, index) => (
                        <PrivateRoute
                          component={item.component}
                          path={item.path}
                          exact
                          key={index}
                        />
                      ))}

                  <PrivateRoute component={CNotFound} path="*" />
                  <PublicRoute component={CNotFound} path="*" />
                </Switch>
              </div>
            </Box>
          </Suspense>
        </UserContext.Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default Routes;
