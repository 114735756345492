import { Nav, NavDropdown, Navbar } from "react-bootstrap";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const CNavDropdown = ({ data, url, setFilters, logOut }) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  return (
    <Navbar>
      <Navbar.Collapse id="navbarScroll">
        <Nav className="flex-column ">
          <NavDropdown
            active={url?.includes(data.route)}
            title={
              <>
                <data.icon className="navbar-icon" />
                <span> {t(`route.${data.display_name}`)}</span>
                <ArrowDropDownIcon />
              </>
            }
            onClick={() => {
              setShow(!show);
            }}
          ></NavDropdown>

          {show &&
            data.children.map((item) => (
              <NavDropdown.Item
                as={Link}
                to={item.route}
                className={url?.includes(item.route) ? "active" : ""}
                key={item.label}
                onClick={item.label === "logout" ? logOut : setFilters}
              >
                {t(`route.${item.label}`)}
              </NavDropdown.Item>
            ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CNavDropdown;
