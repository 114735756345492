import { styled, TextField } from "@mui/material";

const CSearchField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    color: theme.palette.common.black,
    padding: "11px 8px",
    fontSize: "14px",
  },
  "& .MuiFormLabel-root": { color: theme.palette.common.black },
  "& .MuiInputBase-root": { marginBottom: 0 },
  "& .MuiInputLabel-shrink": {
    left: "40px",
    top: "-5px",
    fontSize: "14px",
    opacity: 0.4,
  },
  "& .MuiInputLabel-shrink1": {
    left: "0",
    top: "-5px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
    height: "48px",
    top: "-5px",
  },
}));

export default CSearchField;
