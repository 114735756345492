import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { REDUCER, ACTION } from "@common/constants/redux";

const initialState = { filter: null };

const persistConfig = {
  key: REDUCER.NOTIFICATION_SEGMENT,
  storage: storage,
  whitelist: [],
};

const notificationSegmentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ACTION.SET_NOTIFICATION_SEGMENT_FILTER:
    return {
      ...state,
      filter: payload,
    };
  default:
    return state;
  }
};

export default persistReducer(persistConfig, notificationSegmentReducer);
