import * as PropTypes from "prop-types";

import React from "react";

import { Box, Button, Dialog, Typography } from "@mui/material";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { confirmable } from "react-confirm";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

import moment from "moment";

import { CancelButton } from "../../controls";

const CDialog = ({
  show,
  proceed,
  confirmation,
  deleteAble = true,
  cancelText,
  okText,
  data,
  title = t("common.delete_question"),
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={show}
      onClose={() => proceed(false)}
      className={`confirm-dialog ${deleteAble ? "MuiWarning" : "MuiError"}`}
      sx={{ "& .MuiPaper-root": { maxWidth: "700px" } }}
    >
      <Box
        sx={{
          width: "700px",
          padding: "60px 0",
          textAlign: "center",
        }}
      >
        <ErrorOutlineIcon
          sx={{
            "&.MuiSvgIcon-root": {
              height: "41px",
              width: "41px",
              color: "#FF0000",
            },
          }}
        />
        <Typography sx={{ margin: "10px 0" }}>
          {title + confirmation} ?
        </Typography>
        {data && (
          <>
            <div className="c-modal__bill">
              <div className="c-modal__bill--left">
                <Typography variant="body2" sx={{ paddingRight: "10px" }}>
                  Receipt Number:
                </Typography>
              </div>
              <div className="c-modal__bill--right">
                <Typography variant="h4" sx={{ color: "#000" }}>
                  {data.receipt_number}
                </Typography>
              </div>
            </div>
            <div className="c-modal__bill">
              <div className="c-modal__bill--left">
                <Typography variant="body2" sx={{ paddingRight: "10px" }}>
                  Cashier Code:
                </Typography>
              </div>
              <div className="c-modal__bill--right">
                <Typography variant="h4" sx={{ color: "#000" }}>
                  {data.cashier_code}
                </Typography>
              </div>
            </div>
            <div className="c-modal__bill">
              <div className="c-modal__bill--left">
                <Typography variant="body2" sx={{ paddingRight: "10px" }}>
                  Store code
                </Typography>
              </div>
              <div className="c-modal__bill--right">
                <Typography variant="h4" sx={{ color: "#000" }}>
                  {data.store_name}
                </Typography>
              </div>
            </div>
            <div className="c-modal__bill">
              <div className="c-modal__bill--left">
                <Typography variant="body2" sx={{ paddingRight: "10px" }}>
                  Total
                </Typography>
              </div>
              <div className="c-modal__bill--right">
                <Typography variant="h4" sx={{ color: "#000" }}>
                  {data.total}
                </Typography>
              </div>
            </div>
            <div className="c-modal__bill">
              <div className="c-modal__bill--left">
                <Typography variant="body2" sx={{ paddingRight: "10px" }}>
                  Created Date:
                </Typography>
              </div>
              <div className="c-modal__bill--right">
                <Typography variant="h4" sx={{ color: "#000" }}>
                  {moment(data.created_date).format("DD/MM/YYYY")}
                </Typography>
              </div>
            </div>
          </>
        )}

        <Box sx={{ marginTop: "10px" }}>
          {cancelText && okText ? (
            <>
              <Button
                onClick={() => proceed(false)}
                sx={{
                  mr: 2,
                  color: "#F15E30",
                  borderColor: "#F15E30",
                }}
              >
                {cancelText}
              </Button>
              <Button
                onClick={() => proceed(true)}
                variant="contained"
                sx={{
                  backgroundColor: "#F15E30",
                  color: "#fff",

                  "&:hover": { backgroundColor: "#F15E30" },
                }}
              >
                {okText}
              </Button>
            </>
          ) : (
            <>
              <CancelButton
                onClick={() => proceed(false)}
                sx={{
                  mr: 2,
                  color: "#F15E30",
                  borderColor: "#F15E30",
                }}
              >
                {deleteAble ? t("common.cancel") : "Close"}
              </CancelButton>
              {deleteAble && (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#F15E30",
                    color: "#fff",

                    "&:hover": { backgroundColor: "#F15E30" },
                  }}
                  onClick={() => {
                    proceed(true);
                  }}
                >
                  {t("common.delete")}
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

CDialog.propTypes = {
  cancelText: PropTypes.string,
  confirmation: PropTypes.string,
  data: PropTypes.object,
  deleteAble: PropTypes.bool,
  okText: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func,
};

CDialog.defaultProps = {
  title: t("common.delete_question"),
  confirmation: "Dữ liệu đã xóa sẽ bị xóa vĩnh viễn, không thể phục hồi lại!",
};

export default confirmable(CDialog);
