import * as PropTypes from "prop-types";

import React from "react";
import { Pagination } from "@mui/material";

const CPagination = ({ pages, page, onPageChange }) => {
  return (
    <Pagination
      showFirstButton
      showLastButton
      color="primary"
      count={pages}
      page={page}
      onChange={onPageChange}
      sx={{
        "& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
          backgroundColor: "#F15E30",
        },
        "& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected:hover": {
          backgroundColor: "#F15E30",
        },
        "& .MuiButtonBase-root.MuiPaginationItem-root": { borderRadius: "4px" },
      }}
    />
  );
};

CPagination.propTypes = {
  pages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
};

export default CPagination;
