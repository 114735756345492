import * as PropTypes from "prop-types";

import React from "react";

import { TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";

const CTimePicker = ({
  ampm,
  error,
  helperText,
  value,
  onChange,
  disableOpenPicker,
  ...props
}) => {
  return (
    <>
      <TimePicker
        ampm={ampm ? true : false}
        value={value}
        disableOpenPicker={disableOpenPicker}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            autoComplete="off"
            error={error}
            helperText={
              helperText === undefined || helperText === "undefined"
                ? ""
                : helperText
            }
            sx={{
              "& .MuiInputBase-root": {
                background: "#fff",
                height: "40px",
                flexDirection: "row-reverse",
              },
              "&.MuiFormControl-root ": { height: "40px" },
              "& .MuiInputBase-input ": { textTransform: "capitalize" },
            }}
          />
        )}
        {...props}
      />
    </>
  );
};

CTimePicker.propTypes = {
  ampm: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
  props: PropTypes.object,
};

export default CTimePicker;
