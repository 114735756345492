import * as PropTypes from "prop-types";

import React, { useCallback } from "react";
import { OutlinedInput } from "@mui/material";

import { useTranslation } from "react-i18next";

import { CFormControl } from "@common/components/controls";

import { ALPHA_NUMERIC_DASH_REGEX } from "@common/constants/regexs";

const CGoToPage = ({
  pages,
  value,
  onChange,
  setFilter,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((e) => {
    if (e.target.value === "") {
      onChange(e.target.value);
    } else {
      onChange(Math.max(1, Math.min(pages, Number(e.target.value))));
    }
  }, [onChange, pages]);

  const onKeyDown = useCallback((e) => {
    if (
      !ALPHA_NUMERIC_DASH_REGEX.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight"
    ) {
      e.preventDefault();
    }

    if (e.key === "Enter") {
      setFilter((prevFilter) => {
        return {
          ...prevFilter,
          page: value === "" ? 1 : Number(value),
        };
      });
    }
  }, [setFilter, value]);

  return (
    <CFormControl
      sx={{
        "color": "#000",
        "&.MuiFormControl-root": {
          width: "105px",
          height: "30px",
        },
      }}
    >
      <OutlinedInput
        placeholder={t("common.go_page")}
        value={value}
        type="number"

        onChange={handleChange}
        onKeyDown={onKeyDown}

        sx={{
          "background": "#fff",
          "width": "105px",
          "& .MuiInputBase-input": { height: "10px" },
          "& .MuiOutlinedInput-notchedOutline": { height: "36px" },
        }}
      />
    </CFormControl>
  );
};

CGoToPage.propTypes = {
  pages: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default CGoToPage;
