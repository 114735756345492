import * as PropTypes from "prop-types";

import React from "react";
import { TableCell, TableHead, TableRow } from "@mui/material";

const CTableHead = ({ data, className }) => {
  return (
    <TableHead
      className={className}
      sx={{ height: "44px" }}
    >
      <TableRow>
        {data.map(({
          key,
          label,
          ...prop
        }, index) => (
          <TableCell
            sx={
              label == "" ?
                {
                  position: "sticky",
                  right: 0,
                  background: "#165873",
                  border: " 1px solid #F2F1F6",
                  textAlign: "center",
                  color: "#ffffff",
                  fontSize: "14px",
                  fontWeight: "700",
                  lineHeight: "17px",
                } :
                {
                  background: "#165873",
                  border: " 1px solid #F2F1F6",
                  textAlign: "center",
                  color: "#ffffff",
                  fontSize: "14px",
                  fontWeight: "700",
                  lineHeight: "17px",
                }
            }
            align="justify"
            key={key ?? index}
            {...prop}
          >
            {label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

CTableHead.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
};

export default CTableHead;
