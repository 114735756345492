import { QueryClient } from "react-query";

import createQuery from "@utils/react-query/createQuery";

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: 0,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
    },
  },
});

const set = (key, data, options) => {
  return client.setQueryData(key, data, options);
};

const get = (key, filter) => {
  return client.getQueryData(key, filter);
};

const refetch = (key) => {
  return client.invalidateQueries(key);
};

export default client;

export {
  createQuery, set, get, refetch,
};
