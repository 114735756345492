import * as PropTypes from "prop-types";

import React, { useRef } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

import { Box, Button, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { reactions } from "@common/components/controls/CCkeditor/constants";
import { uploadFile } from "@apis/file.api";

DecoupledEditor.defaultConfig = {
  removePlugins: [],
  toolbar: {
    items: [
      "heading",
      "|",
      "fontfamily",
      "fontsize",
      "fontColor",
      "fontBackgroundColor",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "alignment",
      "|",
      "numberedList",
      "bulletedList",
      "|",
      "outdent",
      "indent",
      "|",
      "link",
      "blockquote",
      "uploadImage",
      "mediaEmbed",
      "|",
      "undo",
      "redo",
    ],
  },
  image: {
    toolbar: [
      "imageStyle:inline",
      "imageStyle:wrapText",
      "imageStyle:breakText",
      "|",
      "toggleImageCaption",
      "imageTextAlternative",
    ],
  },
  table: { contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"] },
  language: "en",
};

const CCkeditor = ({
  error,
  source_category,
  source_code,
  value,

  onChange,
  ...props
}) => {
  const editorRef = useRef();

  const { t } = useTranslation();

  const handleChange = (_event, editor) => {
    const data = editor.getData();
    if (onChange) onChange(data);
  };

  const handleUpload = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            uploadFile(file, source_category, source_code)
              .then((res) => {
                resolve({ default: `${res.data?.url}` });
                toast.error(res?.message);
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  };

  const uploadPlugin = (editor) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return handleUpload(loader);
    };
  };

  const handleEditorReady = (editor) => {
    // Attach the editor to the DOM element
    editor.ui.view.editable.element.parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.view.editable.element
    );

    // Store the editor instance for later use
    editorRef.current = editor;
  };

  return (
    <React.Fragment>
      <Box component="div" className="c-ckeditor">
        <Box
          component="div"
          className={
            error !== "undefined" && error !== undefined
              ? "c-ckeditor__error"
              : ""
          }
        >
          <CKEditor
            {...props}
            onReady={(editor) => {
              editor.ui
                .getEditableElement()
                .parentElement.insertBefore(
                  editor.ui.view.toolbar.element,
                  editor.ui.getEditableElement()
                );
              handleEditorReady(editor);
              // this.editor = editor;
            }}
            onError={(_error, { willEditorRestart }) => {
              if (willEditorRestart) {
                this.editor.ui.view.toolbar.element.remove();
              }
            }}
            onChange={handleChange}
            editor={DecoupledEditor}
            data={value}
            config={{
              placeholder: t("newsfeed.description"),
              extraPlugins: [uploadPlugin],
              mediaEmbed: { previewsInData: true },
            }}
            sx={{ "&.ck-content": { border: "5px solid red" } }}
          />
        </Box>

        {error !== "undefined" && (
          <Typography variant="body1">{error}</Typography>
        )}

        <Box component="div" className="dropdown c-ckeditor__icon">
          <Button type="button" data-bs-toggle="dropdown" sx={{ padding: 0 }}>
            😀
          </Button>

          <Box component="ul" className="dropdown-menu">
            <Box component="div" className="reactions">
              {reactions.map((icon, index) => (
                <Typography
                  key={index}
                  component="span"
                  onClick={() => {
                    // setValue(content, getValues(content).concat(icon));
                    if (editorRef.current) {
                      editorRef.current.model.change((writer) => {
                        const insertionPoint =
                          editorRef.current.model.document.selection.getFirstPosition();
                        writer.insertText(icon, insertionPoint);
                      });
                    }
                  }}
                >
                  {icon}
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

CCkeditor.propTypes = {
  error: PropTypes.string,
  content: PropTypes.string,
  source_category: PropTypes.string,
  source_code: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  props: PropTypes.object,
};

export default CCkeditor;
