import { ACTION } from "@common/constants/redux";

export const setConfigEventsFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: ACTION.SET_CONFIG_EVENTS_FILTER,
      payload: filter,
    });
  };
};
