import { styled, Button } from "@mui/material";

const CustomButton = styled(Button)(({ theme }) => ({ "&.MuiButton-root": { backgroundColor: theme.palette.common?.orange } }));

const CancelButton = styled(Button)(({ theme }) => ({
  "&.MuiButton-root": {
    backgroundColor: "unset",
    color: theme.palette.common?.orange,
    border: "1px solid",
    borderColor: theme.palette.common?.orange,
  },
}));

const PreviewButton = styled(Button)(({ theme }) => ({
  "&.MuiButton-root": {
    backgroundColor: theme.palette.common?.orangeBlur,
    color: theme.palette.common?.orange,
    border: "1px solid",
    borderColor: theme.palette.common?.orange,
  },
}));

export {
  CustomButton, CancelButton, PreviewButton,
};
