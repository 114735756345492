import * as PropTypes from "prop-types";

import React from "react";
import { Checkbox } from "@mui/material";

const CDeleteCheckbox = ({ checked, onChange }) => {
  return (
    <Checkbox
      onChange={onChange}
      checked={checked}
      sx={{
        "&.Mui-checked": { color: "#F15E30" },
        "&.MuiButtonBase-root": { padding: 0 },
      }}
    />
  );
};

CDeleteCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CDeleteCheckbox;
