import { login, profile } from "@apis/auth.api";

import { isSuccess } from "@utils/func";
import { setAuthToken } from "@utils/axios";

import { ACTION } from "@common/constants/redux";
import { checkPermissionNotification } from "../../firebase/firebase";

export const setToken = (token = null) => {
  return {
    type: ACTION.SET_AUTHENTICATE,
    payload: token,
  };
};

export const setUser = (user) => {
  return {
    type: ACTION.SET_CURRENT_USER,
    payload: user,
  };
};

export const setTokens = (refesh_token, access_token) => {
  return async (dispatch) => {
    window.localStorage.setItem(
      // eslint-disable-next-line no-undef
      process.env.REACT_APP_STORE_REFRESH_TOKEN,
      refesh_token
    );

    window.localStorage.setItem("auth", "auth");
    dispatch(setToken(access_token));
  };
};

export const tryLogin = ({ username, password }) => {
  return async (dispatch) => {
    try {
      const res = await login(username, password);

      if (isSuccess(res)) {
        const { access_token, refresh_token } = res.data;

        console.log("login", res);
        dispatch(setUser(res.data));

        dispatch(setTokens(refresh_token, access_token));
        setAuthToken(access_token);

        // checkPermissionNotification();

        return true;
      }

      return false;
    } catch (err) {
      console.log("error: ", err.message);
      return false;
    }
  };
};

export const getProfile = () => {
  return async (dispatch) => {
    const me = await profile();
    return dispatch(setUser(me));
  };
};

export const tryLogout = () => {
  return async (dispatch) => {
    await dispatch(setToken(null));
    await dispatch(setUser(null));

    window.localStorage.clear();
    window.location =
      window.location.protocol + "//" + window.location.host + "/";
  };
};
