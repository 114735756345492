import { lazy } from "react";

const CreateNewsfeedTypePage = lazy(() => import("./create"));
const NewsfeedTypesListPage = lazy(() => import("./main"));
const UpdateNewsfeedTypePage = lazy(() => import("./update"));

export {
  CreateNewsfeedTypePage,
  NewsfeedTypesListPage,
  UpdateNewsfeedTypePage,
};
