import React from "react";

const CLoading = () => {
  return (
    <div className="c-loading">
      <div className="c-loading__spinner"></div>
    </div>
  );
};

export default CLoading;
