import { ACTION } from "@common/constants/redux";

export const toggle = (loading) => {
  return (dispatch) => {
    dispatch({
      type: ACTION.SET_LOADING,
      payload: loading,
    });
  };
};
