import React, { useCallback } from "react";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CancelButton } from "@common/components/controls";

const CGoBack = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <CancelButton
        variant="contained"
        component="label"
        onClick={goBack}
      >
        {t("button.cancel")}
      </CancelButton>
    </>
  );
};

export default CGoBack;
