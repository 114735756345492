import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { REDUCER, ACTION } from "@common/constants/redux";

const initialState = {
  loading: null,
  notifications: [],
};

const persistConfig = {
  key: REDUCER.CONFIG,
  storage: storage,
  whitelist: ["notifications"],
};

const configReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ACTION.SET_LOADING:
    return {
      ...state,
      loading: payload,
    };
  case ACTION.PUSH_NOTIFICATION:
    return {
      ...state,
      notifications: [...state.notifications, payload],
    };
  case ACTION.REMOVE_NOTIFICATION:
    return {
      ...state,
      notifications: state.notifications.filter((v, i) => i !== payload),
    };
  default:
    return state;
  }
};

export default persistReducer(persistConfig, configReducer);
