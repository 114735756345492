import * as PropTypes from "prop-types";

import React from "react";
import { Checkbox } from "@mui/material";

const CNormalCheckbox = ({ checked, onChange }) => {
  return (
    <Checkbox
      onChange={onChange}
      checked={checked}
      sx={{
        color: "#F15E30",
        "&.Mui-checked": { color: "#F15E30" },
      }}
    />
  );
};

CNormalCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default CNormalCheckbox;
