import { createConfirmation } from "react-confirm";
import { moment } from "moment";

import { CDialog } from "@common/components/others";

import { PREFIX_CACHE } from "@common/constants";

export const isLogin = () => {
  if (localStorage.getItem("auth")) return true;
  return false;
};

export const truncate = (str, n) => {
  const indexEnd = str?.indexOf("</p>");
  const newStr = indexEnd > -1 ? str.substr(0, indexEnd) + "..." : str; // trim the content of ckeditor when there are multiple lines

  return newStr?.length > n ? newStr.substr(0, n - 1) + "..." : newStr;
};

export const trimHtml = (str) => {
  if (str === null || str === "") return false;
  else str = str.toString();

  return str.replace(/<[^>]*>|\r&\n|\r\n/g, "");
};

const element = document.createElement("div");
export const decodeHTMLEntities = (str) => {
  if (str && typeof str === "string") {
    str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
    str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
    element.innerHTML = str;
    str = element.textContent;
    element.textContent = "";
  }

  return str;
};

export const isSuccess = (response) => {
  return response.status?.toString()[0] === "2";
};

export const notFound = (response) => {
  return response.status === 404;
};

export const confirm = createConfirmation(CDialog);

export const delay = (number) =>
  new Promise((resolve) =>
    setTimeout(() => {
      resolve();
    }, number)
  );

export const validURL = (str) => {
  const pattern = new RegExp(
    "^https?:\\/\\/" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const reverseString = (str) => {
  return str?.split("-").reverse().join("-");
};

export const sortDate = (a, b) => {
  return moment(a.effected_date) - moment(b.effected_date);
};

export const scrollLayout = (x) => {
  window.scroll({
    top: x,
    behavior: "smooth",
  });
};

export const getFileFromUrl = async (url, name, defaultType = "image/jpeg") => {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, { type: data.type || defaultType });
};

export const generateKeyFromUrl = (url, payload) => {
  const path = `${PREFIX_CACHE}-${url.substring(1).split("/").join("-")}`;
  const encode = payload ? JSON.stringify(payload) : null;
  return path + (encode ? "-" + encode : "");
};
