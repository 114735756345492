import { ACTION } from "@common/constants/redux";

export const setCustomer = (customer) => {
  return (dispatch) => {
    dispatch({
      type: ACTION.SET_CUSTOMER,
      payload: customer,
    });
  };
};

export const setCustomerFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: ACTION.SET_CUSTOMER_FILTER,
      payload: filter,
    });
  };
};
