import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { REDUCER, ACTION } from "../constants/redux";

const initialState = { filter: null };

const persistConfig = {
  key: REDUCER.VOUCHER,
  storage: storage,
  whitelist: [],
};

const voucherReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ACTION.SET_VOUCHER_FILTER:
    return {
      ...state,
      filter: payload,
    };
  default:
    return state;
  }
};

export default persistReducer(persistConfig, voucherReducer);
