import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { REDUCER, ACTION } from "@common/constants/redux";

const initialState = {
  is_logged: false,
  token: null,
  user: null,
};

const persistConfig = {
  key: REDUCER.AUTH,
  storage: storage,
};

const authReducer = (
  state = initialState,
  { type, payload } = {
    type: null,
    payload: null,
  },
) => {
  switch (type) {
  case ACTION.SET_CURRENT_USER:
    return {
      ...state,
      user: payload,
      is_logged: !!payload,
    };
  case ACTION.SET_AUTHENTICATE:
    return {
      ...state,
      token: payload,
    };
  default:
    return state;
  }
};

export default persistReducer(persistConfig, authReducer);
