import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./utils/language/english/en.json";
import translationVN from "./utils/language/vietnamese/vn.json";

const storedLang = localStorage.getItem("i18nextLng");

const resources = {
  en: { translation: translationEN },
  vn: { translation: translationVN },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "vn",
    interpolation: { escapeValue: false },

    resources,
    lng: "vn",

    storedLang,
  });

export default i18n;
