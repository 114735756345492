import { map } from "@utils/axios";

import { FILES } from "@apis/_constant";

export const uploadFile = (file, source_category, source_code) => {
  const data = new FormData();

  data.append("file", file);
  data.append("source_category", source_category);
  data.append("source_code", source_code);

  return map().post(FILES.UPLOAD_FILE, data);
};
