import * as PropTypes from "prop-types";

import React, { useState, useEffect, useRef, useCallback } from "react";

import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";

import slugify from "slugify";

import { delay } from "@utils/func";

const option = {
  root: null,
  rootMargin: "0px",
  threshold: 1.0,
};

const CAutocomplete = ({
  className,
  disabled,
  display,
  error,
  errorText,
  freeStyle,
  loadingItem,
  multiple,
  open,
  options,
  placeholder,
  sx,
  value,
  type,
  onChange,
  onClose,
  onInputChange,
  onKeyDown,
  onOpen,
  hasNextPage,
  loadMore,
}) => {
  const ref = useRef(null);

  const [_nodes, setNodes] = useState(null);
  const [_options, setOptions] = useState([]);
  const [_position, setPosition] = useState(0);
  const [, setElementVisible] = useState(false);

  const loading = open && _options.length === 0;

  useEffect(() => {
    const current = ref.current;
    const observer = new IntersectionObserver(callbackFunction, option);
    if (current) observer.observe(current);

    return () => {
      if (current) observer.unobserve(current);
    };
  }, [callbackFunction]);

  useEffect(() => {
    let active = true;
    if (!loading) return undefined;

    (async () => {
      await delay(500);
      if (active) setOptions([...options]);
    })();

    return () => {
      active = false;
    };
  }, [options, loading]);

  useEffect(() => {
    options && setOptions([...options]);
  }, [options]);

  useEffect(() => {
    if (_nodes && _position && _options.length > 10) {
      _nodes.scrollTop = _position - 200;
    }
  }, [_nodes, _options, _position]);

  const callbackFunction = useCallback((entries) => {
    const [entry] = entries;
    setElementVisible(entry.isIntersecting);
  }, []);

  const compare = useCallback((option, value) => {
    return value === undefined || value === "" || option?.id === value?.id;
  }, []);

  const getOptionLabel = (option) => {
    if (option !== undefined) {
      if (typeof option === "string") return option;
      if (option?.inputValue) return option.inputValue;

      if (Array.isArray(display)) {
        let stringg = "";
        display.forEach((e, i) => {
          i !== display.length - 1
            ? (stringg += `${option[e]} - `)
            : (stringg += `${option[e]}`);
        });

        return stringg;
      }

      return option?.[display];
    } else return "";
  };

  const onFilter = (option, inputValue) => {
    return option.map((item) => {
      const itemFormat = slugify(item?.label ? item?.label : item?.name, {
        replacement: " ",
        lower: true,
        locale: "vi",
      });

      if (
        itemFormat.indexOf(
          slugify(inputValue.inputValue, {
            replacement: " ",
            lower: true,
            locale: "vi",
          })
        ) !== -1
      ) {
        return item;
      }
    });
  };

  const onScroll = (event) => {
    const { currentTarget } = event;
    setNodes(currentTarget);

    if (hasNextPage) {
      if (
        Math.ceil(currentTarget.scrollTop) + currentTarget.clientHeight >=
          currentTarget.scrollHeight &&
        hasNextPage
      ) {
        loadMore();
        setPosition(currentTarget.scrollHeight);
      }
    } else {
      if (
        currentTarget.scrollTop + currentTarget.clientHeight ===
        currentTarget.scrollHeight
      ) {
        setPosition(currentTarget.scrollHeight);
      }
    }
  };

  const renderInput = useCallback(
    (params) => (
      <TextField
        {...params}
        placeholder={placeholder}
        error={error}
        type={type}
        hiddenLabel={true}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
      />
    ),
    [error, loading, placeholder, type]
  );

  const renderOptions = useCallback((_, option) => {
    if (option) {
      return (
        <Box
          key={_["data-option-index"]}
          sx={{
            fontSize: "14px",
            color: "#000",
            overflow: "auto",
          }}
          {..._}
        >
          {_.key}
        </Box>
      );
    }
  }, []);

  return (
    <React.Fragment>
      <Autocomplete
        className={className}
        disabled={disabled}
        disablePortal={true}
        freeSolo={freeStyle}
        multiple={multiple}
        noOptionsText="No data found"
        open={open}
        options={_options}
        value={value}
        filterOptions={loadingItem ? (x) => x : onFilter}
        isOptionEqualToValue={compare}
        getOptionLabel={getOptionLabel}
        renderInput={renderInput}
        renderOption={renderOptions}
        onChange={onChange}
        onClose={onClose}
        onInputChange={onInputChange}
        onKeyDown={onKeyDown}
        onOpen={onOpen}
        sx={
          sx
            ? sx
            : {
                background: "#fff",
                borderRadius: "4px",
                height: "42px",
                "& .MuiFormLabel-root": { fontSize: "14px" },
                "& .MuiInputBase-root .Mui-disabled": { background: "e6e6e6" },
                "& .MuiInputBase-input": {
                  fontSize: "14px",
                  minWidth: "55px !important",
                  padding: "4px 4px 3.5px 2px !important",
                },
                "& .MuiOutlinedInput-notchedOutline": { height: "50px" },
              }
        }
        ListboxProps={{ onScroll }}
      />

      {errorText ? <Typography variant="body1">{errorText}</Typography> : <></>}
    </React.Fragment>
  );
};

CAutocomplete.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  display: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  freeStyle: PropTypes.bool,
  loadingItem: PropTypes.bool,
  multiple: PropTypes.bool,
  placeholder: PropTypes.string,
  sx: PropTypes.object,
  options: PropTypes.array,
  value: PropTypes.any,
  open: PropTypes.bool,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onInputChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onOpen: PropTypes.func,
};

export default CAutocomplete;
