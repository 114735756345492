import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    common: {
      grey: "#4A4A4A",
      black: "#000",
      green: "#165873",
      orange: "#F15E30",
      orangeBlur: "#FFE1D2",
      white: "#fff",
      greydark: "#808080",
      greylight: "#F2F1F6",
      greylightBlur: "#f2f1f66e",
    },
    info: { main: "#428bca" },
    primary: { main: "#F15E30" },
    secondary: { main: "#FDCEC0" },
    error: { main: "#e5343d" },
    warning: { main: "#ffb61e" },
    success: { main: "#558b2f" },
    text: {
      primary: "#a5a9ad",
      secondary: "#fff",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "20px",
          padding: "10px 15px",
          textTransform: "unset",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "h2",
          subtitle2: "h2",
          body1: "p",
          body2: "span",
          require: "span",
        },
      },
    },
  },
  typography: {
    // fontFamily: `"Inter", sans-serif`,
    fontFamily: "Times New Roman",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,

    h2: {
      fontSize: "16px",
      fontWeight: 700,
      color: "#fff",
    },

    h3: {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "24px",
      color: "#165873",
    },

    h4: {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "17px",
      color: "#4A4A4A",
    },

    h5: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#000 ",
    },

    body1: {
      fontSize: "12px",
      lineHeight: "24px",
      fontWeight: 400,
      marginBottom: "4px",
      color: "#d32f2f",
    },

    body2: {
      fontSize: "14px",
      lineHeight: "24px",
      color: "#000",
      fontWeight: 400,
      marginBottom: "4px",
    },

    datePreview: {
      fontSize: "12px",
      fontWeight: 300,
      color: "#fff",
    },

    require: {
      color: "#FF0000",
      fontSize: "14px",
      fontWeight: 400,
      marginLeft: "4px",
    },

    summary: {
      color: "#fff",
      fontSize: "12px",
      fontWeight: 300,
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      smex: 500,
      sm: 600,
      smm: 800,
      md: 900,
      mdd: 1100,
      lg: 1200,
      lgg: 1400,
      xl: 1536,
    },
  },
});

export default theme;
