import React, { useEffect } from "react";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";

import Routes from "@routes/Routes";
import { Slide, ToastContainer } from "react-toastify";

import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import { CLoading } from "@common/components/controls";

import { setAuthToken } from "@utils/axios";

const selector = createSelector(
  (state) => state.config,
  (state) => state.auth,
  ({ loading }, { token }) => ({
    loading,
    token,
  })
);

const App = () => {
  const { loading, token } = useSelector(selector);

  useEffect(() => {
    const init = async () => {
      window.loading(true);

      try {
        if (token) await setAuthToken(token);
      } catch (err) {
        /* empty */
      }

      window.loading(false);
    };

    init();
  }, [token]);

  if (loading !== false) return <CLoading />;

  return (
    <React.Fragment>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        theme="dark"
        transition={Slide}
      />

      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Routes />
      </LocalizationProvider>
    </React.Fragment>
  );
};

export default App;
