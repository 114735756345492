import { map } from "@utils/axios";
import { RESERVATION } from "./_constant";

export const getReservation = (params) => {
  return map().post(RESERVATION.GET, params);
};

export const cancel = (id, params) => {
  return map().put(`${RESERVATION.CANCEL}/${id}`, params);
};

export const comfirm = (params) => {
  return map().post(RESERVATION.COMFIRM, params);
};
