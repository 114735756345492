import { ACTION } from "@common/constants/redux";

export const addNewsFeed = (data) => ({
  type: ACTION.ADD_NEWSFEED,
  newfeeds: data,
});

export const updateNewsfeed = (data) => ({
  type: ACTION.UPDATE_NEWSFEED,
  newfeeds: data,
});

export const unlinkNewsfeed = (id) => ({
  type: ACTION.DELETE_NEWSFEED,
  newfeeds: id,
});

export const setNewsfeedFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: ACTION.SET_NEWSFEED_FILTER,
      payload: filter,
    });
  };
};
