import * as PropTypes from "prop-types";

import React from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";

import { useHistory, useRouteMatch } from "react-router-dom";

const CTableBody = ({
  data,
  disabled,
  headers,
  customer,
  render,
  saveFilter,
}) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  return (
    <TableBody
      sx={{ background: "#ffffff" }}
    >
      {data?.map((row, index) => (
        <TableRow
          key={row?.id ?? index}
          sx={{ height: "44px" }}
        >
          {headers.map(({ key }) => (
            <TableCell
              key={row?.id ? `${row.id}_${key}` : `${index}_${key}`}

              sx={{
                textAlign: "center",
                border: " 1px solid #F2F1F6",
                fontWeight: "400",
                fontSize: "14px",
                padding: "0px 10px",
              }}

              style={
                key == "action" ?
                  {
                    position: "sticky",
                    right: 0,
                    background: "white",
                  } :
                  {}
              }

              onClick={(event) => {
                if (
                  !event.ignore &&
                  !disabled &&
                  !row.delete_flag
                ) {
                  history.push(
                    `${url}/${customer ? row.customer_code : row.id}`,
                  );

                  !!saveFilter && saveFilter();
                }
              }}
            >
              <div
                style={{
                  overflow: "hidden",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                  display: "-webkit-box",
                }}
              >
                {render?.[key] ? render[key](row[key], row, index) : row[key]}
              </div>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

CTableBody.propTypes = {
  data: PropTypes.array,
  disabled: PropTypes.bool,
  headers: PropTypes.array,
  customer: PropTypes.object,
  render: PropTypes.object,
  saveFilter: PropTypes.bool,
};

export default CTableBody;
