import * as PropTypes from "prop-types";

import React, { forwardRef } from "react";

import { DatePicker } from "@mui/x-date-pickers";
import { styled, TextField } from "@mui/material";

const DatePickerStyle = styled(DatePicker)(({ theme }) => ({
  "&.MuiFormControl-root ": {
    background: theme.palette.common.white,
    height: "40px",
    borderRadius: "5px",
  },
  "& .MuiOutlinedInput-notchedOutline ": {
    borderColor: "transparent",
    height: "46px",
    top: "-6px",
  },
  "& .MuiInputBase-root ": {
    borderColor: "transparent",
    height: "40px",
  },
}));

const CDatePicker = forwardRef(
  (
    { disabled, error, helperText, value, onChange, ...props },
    // eslint-disable-next-line no-unused-vars
    ref
  ) => {
    return (
      <React.Fragment>
        <DatePickerStyle
          margin="normal"
          inputFormat="DD/MM/yyyy"
          views={["day", "year", "month"]}
          value={value}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: disabled === true ? "" : "dd/mm/yyyy",
              }}
              error={error}
              // When use yup with react i18n return string "undefined"
              helperText={
                helperText === undefined ||
                helperText === "undefined" ||
                helperText === "undefinedfalse"
                  ? ""
                  : helperText
              }
              disabled={disabled}
              onMouseDown={(e) => {
                disabled === true && e.preventDefault();
              }}
              autoComplete="off"
              sx={{ "& .MuiInputBase-root": { flexDirection: "row-reverse" } }}
            />
          )}
          {...props}
        />
      </React.Fragment>
    );
  }
);

CDatePicker.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  props: PropTypes.object,
};

CDatePicker.displayName = "Common - CDatePicker component";

export default CDatePicker;
