import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import QueryClientProvider from "./common/context/react-query";

import client from "./utils/react-query";
import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "./store";

import "./common/assets/css/style.scss";
import "./i18n";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={client}>
          <App />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
