export const AUTH = "_auth";
export const CONFIG = "_config";
export const NEWSFEED = "_newsfeed";
export const NOTIFICATION_CONTENT = "_notification_content";
export const NOTIFICATION_CAMPAIGN = "_notification_campaign";
export const NOTIFICATION_SEGMENT = "_notification_segment";
export const CUSTOMER = "_customer";
export const VOUCHER = "_voucher";
export const INVENTORY = "_inventory";
export const SONG = "_song";
export const POPUP = "_popup";
export const CONFIG_EVENTS = "_config_event";
export const HOLIDAY = "_holiday";
export const TIME_CONFIG = "_time_config";
