import * as PropTypes from "prop-types";

import React from "react";

import { Timeline } from "@mui/lab";
import { Box, Typography } from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { useTranslation } from "react-i18next";

import { CPagination } from "@common/components/controls";
import { CTimeline } from "@common/components/others";

const CHistory = ({ data, paginate, show, onClick, onPageChange }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Box
        component="div"
        className="c-layout__content--detail--history"
        onClick={onClick}
      >
        {!show ? <ArrowRightIcon /> : <ArrowDropDownIcon />}

        <Typography variant="body2" sx={{ marginTop: "10px" }}>
          {t("common.updated_history")}
        </Typography>
      </Box>

      {show && (
        <Box component="div" className="c-layout__content--detail--timeline">
          <Timeline sx={{ "& .MuiTimelineItem-root:before": { flex: 0 } }}>
            <CTimeline data={data} />
          </Timeline>

          <CPagination
            pages={paginate.pages}
            page={paginate.page}
            onPageChange={onPageChange}
          />
        </Box>
      )}
    </React.Fragment>
  );
};

CHistory.propTypes = {
  data: PropTypes.array,
  paginate: PropTypes.object,
  show: PropTypes.bool,
  onClick: PropTypes.func,
  onPageChange: PropTypes.func,
};

export default CHistory;
