import * as PropTypes from "prop-types";

import React, { useEffect, useState } from "react";

const CImage = ({
  alt,
  placeholder,
  src,
  ...props
}) => {
  const [imgSrc, setImgSrc] = useState(placeholder || src);

  const customClass =
    imgSrc === placeholder ? "c-img c-img__loading" : "c-img c-img__loaded";

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);

  return (
    <img
      src={imgSrc}
      alt={alt || ""}
      className={`${customClass}`}
      {...props}
    />
  );
};

CImage.propTypes = {
  alt: PropTypes.string,
  placeholder: PropTypes.string,
  src: PropTypes.string,
  props: PropTypes.object,
};

export default CImage;
