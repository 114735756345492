import { lazy } from "react";

const CustomerPage = lazy(() => import("./main"));
const PointHistoryPage = lazy(() => import("./point-history"));
const ProfilePage = lazy(() => import("./profile"));
const TransactionPage = lazy(() => import("./transaction-history"));
const UpdateCustomerPage = lazy(() => import("./update"));
const VoucherHistoryPage = lazy(() => import("./voucher-history"));

export {
  CustomerPage, PointHistoryPage, ProfilePage, TransactionPage,
  UpdateCustomerPage, VoucherHistoryPage,
};
