import _instance from ".";

export function setAuthToken(token) {
  if (token) {
    _instance.defaults.headers[
      // eslint-disable-next-line no-undef
      process.env.REACT_APP_AXIOS_TOKEN_HEADER
    ] = `Bearer ${token}`;
  } else {
    delete _instance.defaults.headers.common[
      // eslint-disable-next-line no-undef
      process.env.REACT_APP_AXIOS_TOKEN_HEADER
    ];
  }
}
