import { styled, FormControl } from "@mui/material";

const CFormControl = styled(FormControl)(() => ({
  width: "250px",
  "&.MuiFormControl-root": {
    // background: "#fff",
    height: "max-content !important",
    borderRadius: "5px",
    width: "100%",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    // background: "#fff",
    height: "46px",
    top: "-4px",
    borderColor: " rgb(0 0 0 / 0%)",
    borderRadius: "5px",
  },
  "& .MuiInputBase-input": { padding: "10.5px 14px" },
}));

export default CFormControl;
