/* eslint-disable no-unused-vars */
import * as PropTypes from "prop-types";

import React, { forwardRef } from "react";

import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import CSearchField from "./CSearchField";

const CSearchInput = forwardRef(({
  label,
  value,
  onChange,
  onKeyDown,
}, ref) => {
  return (
    <React.Fragment>
      <CSearchField
        className="c-layout__content--field--search"
        label={label}
        InputLabelProps={{
          shrink: value?.length > 0,
          className:
              value?.length > 0 ?
                "MuiInputLabel-shrink1" :
                "MuiInputLabel-shrink",
        }}
        sx={{
          "width": "100%",
          "&.MuiFormControl-root": {
            background: "#fff",
            borderRadius: "5px",
          },
        }}
        onKeyDown={onKeyDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={onChange}
        value={value}
        autoComplete="off"
      />
    </React.Fragment>
  );
},
);

CSearchInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
};

CSearchInput.displayName = "Common - CSearchInput component";

export default CSearchInput;
