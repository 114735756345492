import * as PropTypes from "prop-types";

import React from "react";

import {
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";

import { Typography } from "@mui/material";

import moment from "moment";

import { CDotTimeline } from "@common/components/controls";

const CTimeline = ({ data }) => {
  return data.map((item, index) => (
    <TimelineItem key={index}>
      <TimelineSeparator>
        <CDotTimeline />
        <TimelineConnector
          sx={{ "&.MuiTimelineConnector-root": { backgroundColor: "#000" } }}
        />
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant="h4">{item.updated_by}</Typography>
        <Typography variant="body2">
          {moment(new Date(item.updated_at)).format("DD/MM/YYYY HH:mm:ss")}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  ));
};

CTimeline.propTypes = { data: PropTypes.array };

export default CTimeline;
