/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo } from "react";
import { useQuery } from "react-query";

import { set, refetch } from "@utils/react-query";

const createQuery = (key, func, options = {}) => {
  const {
    data,
    loading,
    ...query
  } = useQuery(key, func, options);

  const _data = useMemo(
    () => (data ? (options.map ? options.map(data) : data) : undefined),
    [loading, data],
  );

  return {
    ...query,
    loading,
    data: _data,
    set: (data) => set(key, data),
    refetch: () => refetch(key),
  };
};

export default createQuery;
