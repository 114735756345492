import auth from "./auth.reducer";
import config from "./config.reducer";
import customer from "./customer.reducer";
import inventory from "./inventory.reducer";
import newsfeed from "./newsfeed.reducer";
import notification_content from "./notification-content.reducer";
import notification_campaign from "./notification-campaign.reducer";
import notification_segment from "./notification-segment.reducer";
import song from "./song.reducer";
import voucher from "./voucher.reducer";
import popup from "./popup.reducer";
import events from "./config-event.reducer";
import holiday from "./holiday.reducer";
import time from "./time-config.reducer";

const rootReducer = {
  auth,
  config,
  newsfeed,
  notification_content,
  notification_campaign,
  notification_segment,
  customer,
  voucher,
  inventory,
  song,
  popup,
  events,
  holiday,
  time,
};

export default rootReducer;
