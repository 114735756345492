import * as yup from "yup";

export const validationSchema = yup.object({
  username: yup
    .string("Bạn vui lòng nhập tài khoản")
    .required("Bạn vui lòng nhập tài khoản"),
  password: yup
    .string("Bạn vui lòng nhập mật khẩu")
    .required("Bạn vui lòng nhập mật khẩu"),
});
