import { ACTION } from "@common/constants/redux";

export const setHolidayFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: ACTION.SET_HOLIDAY_FILTER,
      payload: filter,
    });
  };
};
