
export const reactions = [
  "😀",
  "😆",
  "😄",
  "😅",
  "😂",
  "🤣",
  "😊",
  "😭",
  "🤡",
  "👹",
  "👻",
  "😍",
  "😘",
  "🙃",
  "🙃",
  "😳",
  "😱",
  "😪",
  "😴",
  "👍",
  "✌️",
  "👌",
  "👏",
  "👤",
  "🎃",
  "💛",
  "💚",
  "💙",
  "💜",
  "🖤",
  "💔",
  "❣️",
  "💕",
  "💞",
  "💓",
  "💗",
  "💖",
  "💘",
  "💝",
  "💟",
  "😀",
  "😃",
  "😄",
  "😁",
  "😆",
  "😅",
  "😂",
  "🤣",
  "☺️",
  "😊",
  "😇",
  "🙂",
  "🙃",
  "😉",
  "😍",
  "😌",
  "😘",
  "😗",
  "😙",
  "😚",
  "😋",
  "😜",
  "😝",
  "😛",
  "🤑",
  "🤗",
  "🤓",
  "😎",
  "🤡",
  "🤠",
  "😏",
  "😒",
  "😞",
  "😔",
  "😟",
  "😕",
  "🙁",
  "☹️",
  "😣",
  "😖",
  "😫",
  "😩",
  "😤",
  "😡",
  "😶",
  "😐",
  "😑",
  "😯",
  "😦",
  "😧",
  "😮",
  "😦",
  "😧",
  "😮",
  "😲",
  "😵",
  "😳",
  "😨",
  "😰",
  "😢",
  "😥",
  "🤤",
  "😓",
  "😭",
  "😪",
  "😴",
  "🙄",
  "🤔",
  "🤥",
  "😬",
  "🤐",
  "🤢",
  "🤧",
  "😷",
  "🤒",
  "🤕",
  "😈",
  "👿",
  "👹",
  "👺",
  "💩",
  "👻",
  "💀",
  "☠️",
  "👽",
  "👾",
  "🤖",
  "🎃",
  "😺",
  "😸",
  "😹",
  "😻",
  "😼",
  "😽",
  "🙀",
  "😿",
  "😾",
  "👐",
  "🙌",
  "👏",
  "🙏",
  "🤝",
  "👎",
  "👊",
  "✊",
  "🤛",
  "🤜",
  "🤞",
  "✌️",
  "🤘",
  "👌",
  "👈",
  "👉",
  "👆",
  "👇",
  "☝️",
  "✋",
  "🤚",
  "🖐",
  "🖖",
  "👋",
  "🤙",
  "💪",
  "🖕",
  "✍️",
  "🤳",
  "💅",
  "💍",
  "💄",
  "💋",
  "👄",
  "👅",
  "👂",
  "👃",
  "👣",
  "👁",
  "👀",
  "🗣",
  "👤",
  "👥",
  "👶",
  "👦",
  "👧",
  "👨",
  "👩",
  "👱",
  "👴",
  "👵",
  "👲",
  "👳",
  "👮",
  "👷",
  "💂",
  "🕵️",
  "🤶",
  "🎅",
  "👸",
  "🤴",
  "👰",
  "🤵",
  "👼",
  "🤰",
  "🙇",
  "💁",
  "🙅",
  "🙆",
  "🙋",
  "🙎",
  "🙍",
  "💇",
  "💆",
  "🕴",
  "💃",
  "🕺",
  "👯",
  "🚶",
  "🏃",
  "👫",
  "👭",
  "👬",
  "💑",
  "👨‍❤️‍💋‍👨",
  "❤️",
  "💑",
  "👨‍❤️‍👨",
  "💏‍",
  "👩‍❤️‍👩",
  "👪",
  "👨‍👩‍👧",
  "👨‍👩‍👧‍👦",
  "👨‍👩‍👧‍👧",
  "👩‍👩‍👦",
  "👩‍👩‍👧",
  "👩‍👩‍👧‍👦",
  "👩‍👩‍👦‍👦",
  "👩‍👩‍👧‍👧",
  "👨‍👨‍👦",
  "👨‍👨‍👧",
  "👨‍👨‍👧‍👦",
  "👨‍👨‍👦‍👦",
  "👨‍👨‍👧‍👧",
  "👚‍",
  "👕",
  "👖",
  "👔",
  "👗",
  "👘",
  "👠",
  "👡",
  "👢",
  "👞",
  "👟",
  "👒",
  "🎩",
  "🎓",
  "👑",
  "⛑",
  "🎒",
  "👝",
  "👛",
  "👜",
  "💼",
  "👓",
  "🌂",
  "☂️",
  "🔥",
  "💥",
  "☀️",
  "🌦",
  "🌧",
  "⛈",
  "🌩",
  "🌨",
  "⛅️",
  "🌤",
  "🆘",
  "❌",
  "💯",
  "🚫",
  "💢",
  "🔇",
  "🔊",
];
